import React from 'react';
import { Input } from '@mui/material';

export default function TargetVolume(props) {
  const { targetVolume, setTargetVolume } = props;

  return (
    <Input
      sx={{ mx: 20 }}
      inputProps={{ min: 0, style: { textAlign: 'center' } }}
      type="number"
      value={targetVolume}
      onChange={(e) => {
        const newValue = e.target.value;
        setTargetVolume(newValue === '' ? newValue : Number(newValue));
      }}
    />
  );
}
