import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import DropZoneWrapper from './reusableComponents/DropZoneWrapper';
import History from './History';
import { dictAdd, dictDelete, dictUpdate } from './utils/objectManipulation';
import DownloadButton from './reusableComponents/DownloadButton';
import Objectives from './Objectives';
import Constraints from './Constraints';
import Solutions from './Solutions';
import parseXlsx from './utils/parseXlsx';

export default function Optimization() {
  const [products, setProducts] = useState({});
  const [properties, setProperties] = useState([]);
  const [productsProperties, setProductsProperties] = useState({});
  const [propertyConstraints, setPropertyConstraints] = useState({});
  const [productConstraints, setProductConstraints] = useState({});
  const [finalVolume, setFinalVolume] = useState('');
  const [optimizationsHistory, setOptimizationsHistory] = useState([]);
  const [optimizationResult, setOptimizationResult] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const localHistory = localStorage.getItem('optimizations-history');
    if (localHistory) {
      const histories = JSON.parse(localHistory).map((historyOrig, i) => {
        const history = { ...historyOrig };
        if (!('id' in history)) {
          history.id = uuidv4();
        }
        if (!('name' in history)) {
          history.name = i;
        }
        if (!('date' in history)) {
          history.date = '';
        }
        return history;
      });
      setOptimizationsHistory(histories);
    }
  }, []);

  const emptyConstraint = () => ({
    lower_bound: '',
    target: '',
    weight: '',
    upper_bound: '',
  });

  const reset = () => {
    setFinalVolume('');
    setOptimizationResult(null);
    setProductConstraints({});
    setPropertyConstraints({});
    setProducts({});
    setProperties({});
    setProductsProperties({});
  };

  const loadJson = (content) => {
    reset();
    setProperties(content.properties);
    setProducts(content.products);
    setPropertyConstraints(content.propertyConstraints);
    setProductConstraints(content.productConstraints);
    setProductsProperties(content.productsProperties);
    if ('result' in content) {
      setOptimizationResult({ ...content.result, status: content.result.lightSuccess ? 'success' : 'failure' });
    } else {
      setOptimizationResult(null);
    }
    if ('finalVolume' in content) {
      setFinalVolume(content.finalVolume);
    } else {
      setFinalVolume('');
    }
  };

  const loadXlsx = (content) => {
    const json = parseXlsx(content);
    loadJson(json);
  };

  const instantiateHistory = (historyId) => {
    loadJson(optimizationsHistory.find((history) => history.id === historyId));
  };

  const renameHistory = (historyId, newName) => {
    const histories = optimizationsHistory.map((history) => {
      const newHistory = { ...history };
      if (history.id === historyId) {
        newHistory.name = newName;
      }
      return newHistory;
    });
    setOptimizationsHistory(histories);
    localStorage.setItem('optimizations-history', JSON.stringify(histories));
  };

  const deleteAllHistory = () => {
    setOptimizationsHistory([]);
    localStorage.setItem('optimizations-history', JSON.stringify([]));
  };

  const deleteHistory = (historyId) => {
    const histories = optimizationsHistory.filter((history) => history.id !== historyId);
    setOptimizationsHistory(histories);
    localStorage.setItem('optimizations-history', JSON.stringify(histories));
  };

  const dumpJson = () => {
    const d = new Date();
    return {
      properties,
      productsProperties,
      products,
      propertyConstraints,
      productConstraints,
      result: optimizationResult,
      finalVolume,
      date: d.toLocaleString(),
      name: d.toLocaleString(),
      id: uuidv4(),
    };
  };

  const addResult = (result) => {
    setOptimizationResult(result);
    const newHistory = dumpJson();
    newHistory.result = result;
    const newHistoryList = [...optimizationsHistory, newHistory];
    setOptimizationsHistory(newHistoryList);
    localStorage.setItem('optimizations-history', JSON.stringify(newHistoryList));
  };

  const addItem = (dict, setDict, name) => {
    const newItemId = uuidv4();
    const newItem = {
      id: newItemId,
      name,
    };
    dictAdd(dict, setDict, newItemId, newItem);
    return newItem;
  };

  const addProperty = (name) => {
    const newProperty = addItem(properties, setProperties, name);
    const newProductProperties = { ...productsProperties };
    Object.keys(products).forEach((productId) => {
      newProductProperties[productId][newProperty.id] = 0;
    });
    setProductsProperties(newProductProperties);
    dictAdd(propertyConstraints, setPropertyConstraints, newProperty.id, emptyConstraint());
  };

  const addProduct = (name) => {
    const newProduct = addItem(products, setProducts, name);
    const newProductProperties = { [newProduct.id]: {} };
    Object.keys(properties).forEach((propertyId) => {
      newProductProperties[newProduct.id][propertyId] = 0;
    });
    setProductsProperties({ ...productsProperties, ...newProductProperties });
    dictAdd(productConstraints, setProductConstraints, newProduct.id, emptyConstraint());
  };

  const deleteProperty = (propertyId) => {
    setOptimizationResult(null);
    const newProductProperties = { ...productsProperties };
    Object.keys(products).forEach((productId) => {
      const updatedProductProperties = { ...newProductProperties[productId] };
      delete updatedProductProperties[propertyId];
      newProductProperties[productId] = updatedProductProperties;
    });
    dictDelete(properties, setProperties, propertyId);
    dictDelete(propertyConstraints, setPropertyConstraints, propertyId);
    setProductsProperties(newProductProperties);
  };

  const deleteProduct = (productId) => {
    setOptimizationResult(null);
    dictDelete(productConstraints, setProductConstraints, productId);
    dictDelete(productsProperties, setProductsProperties, productId);
    dictDelete(products, setProducts, productId);
  };

  const renameProperty = (propertyId, newName) => {
    dictUpdate(properties, setProperties, propertyId, 'name', newName);
  };
  const renameProduct = (productId, newValue) => {
    dictUpdate(products, setProducts, productId, 'name', newValue);
  };
  const updateProductConstraint = (productId, field, newValue) => {
    dictUpdate(productConstraints, setProductConstraints, productId, field, newValue);
  };
  const updatePropertyConstraint = (propertyId, field, newValue) => {
    dictUpdate(propertyConstraints, setPropertyConstraints, propertyId, field, newValue);
  };
  const updateProductProperty = (productId, propertyId, newValue) => {
    dictUpdate(productsProperties, setProductsProperties, productId, propertyId, newValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px',
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            mb: 2, mt: 2, display: 'flex', justifyContent: 'space-around', padding: '2px',
          }}
        >
          {t('main_page.title')}
        </Typography>
        <DropZoneWrapper loadJson={loadJson} loadXlsx={loadXlsx}>
          <Box
            sx={{
              display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'center', alignItems: 'flex-end',
              }}
            >
              <DownloadButton data={dumpJson()} />
              <Button color="error" variant="outlined" onClick={reset}>
                {t('main_page.reset')}
              </Button>
            </Box>
            <Objectives
              properties={properties}
              renameProperty={renameProperty}
              addProperty={addProperty}
              deleteProperty={deleteProperty}
              propertyConstraints={propertyConstraints}
              updatePropertyConstraint={updatePropertyConstraint}
              finalVolume={finalVolume}
              setFinalVolume={setFinalVolume}
            />
            <Constraints
              products={products}
              properties={properties}
              renameProduct={renameProduct}
              addProduct={addProduct}
              deleteProduct={deleteProduct}
              productConstraints={productConstraints}
              updateProductConstraint={updateProductConstraint}
              productsProperties={productsProperties}
              updateProductProperty={updateProductProperty}
            />
            <Solutions
              products={products}
              properties={properties}
              productsProperties={productsProperties}
              propertyConstraints={propertyConstraints}
              productConstraints={productConstraints}
              finalVolume={finalVolume}
              optimizationResult={optimizationResult}
              setOptimizationResult={setOptimizationResult}
              addResult={addResult}
              dumpJson={dumpJson}
            />
          </Box>
        </DropZoneWrapper>
      </Box>
      <History
        optimizationsHistory={optimizationsHistory}
        instantiateHistory={instantiateHistory}
        deleteAllHistory={deleteAllHistory}
        renameHistory={renameHistory}
        deleteHistory={deleteHistory}
      />
    </Box>
  );
}
