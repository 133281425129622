import React from 'react';
import {
  Box, Table, TableBody, tableCellClasses, TableContainer,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import tinycolor from 'tinycolor2';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedRow from './EnhancedRow';
import EnhancedInputRow from './EnhancedInputRow';

const descendingComparator = (a, b, order, orderBy, footers) => {
  if (footers.includes(a.id) && !footers.includes(b.id)) {
    return order === 'desc' ? 1 : -1;
  }
  if (footers.includes(b.id) && !footers.includes(a.id)) {
    return order === 'desc' ? -1 : 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy, footers) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, order, orderBy, footers)
  : (a, b) => descendingComparator(b, a, order, orderBy, footers));

const defaultHiddens = ['id'];

export default function EnhancedTable(props) {
  const {
    tableTitle,
    items,
    titles = [],
    naming,
    updateItem,
    addItem,
    updateName,
    deleteItem,
    hiddens = defaultHiddens,
    background,
    editMode = 'open',
    voidable = true,
    voidValue = '',
    dense = false,
    decimals = null,
    footers = [],
    isValid = () => (true),
  } = props;

  const [lightBG, setLightBG] = React.useState();
  const [darkBG, setDarkBG] = React.useState();
  const [borderColor, setBorderColor] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');

  React.useEffect(() => {
    const lightBg = background.light;
    const darkBg = background.dark;
    const darkerBG = tinycolor(background.dark).darken(10).toString();
    setLightBG(lightBg);
    setDarkBG(darkBg);
    setBorderColor(darkerBG);
  }, [background]);

  React.useEffect(() => {
    const newRows = Object.keys(naming).map((itemId) => ({
      name: naming[itemId].name,
      id: itemId,
      ...items[itemId],
    }));
    setRows(newRows);
    const exampleItem = newRows.at(0);
    if (exampleItem) {
      const newFields = Object.keys(exampleItem).filter((field) => !hiddens.includes(field));
      setFields(newFields);
    } else {
      setFields([]);
    }
  }, [items, hiddens, naming]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box maxWidth="lg" sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, backgroundColor: background.main }}>
        <EnhancedTableToolbar title={tableTitle} bgcolor={background.main} />
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table
            sx={{
              minWidth: 630,
              [`& .${tableCellClasses.root}`]: {
                borderColor,
              },
            }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              fields={fields}
              titles={titles}
            />
            <TableBody>
              {rows.slice().sort(getComparator(order, orderBy, footers))
                .map((row, index) => (
                  <EnhancedRow
                    key={row.id}
                    bgcolor={index % 2 ? lightBG : darkBG}
                    row={row}
                    deleteItem={deleteItem}
                    updateItem={updateItem}
                    updateName={updateName}
                    fields={fields}
                    voidable={voidable}
                    voidValue={voidValue}
                    editMode={editMode}
                    decimals={decimals}
                    isValid={isValid}
                  />
                ))}
              {addItem && (
                <EnhancedInputRow
                  bgcolor={rows.length % 2 ? lightBG : darkBG}
                  addItem={addItem}
                  fields={fields}
                  dense={dense}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
