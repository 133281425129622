export function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v !== ''));
}

export function dictUpdate(dict, setDict, itemId, field, value) {
  const dictCopy = { ...dict };
  const itemCopy = { ...dictCopy[itemId] };
  itemCopy[field] = value;
  dictCopy[itemId] = itemCopy;
  setDict(dictCopy);
}

export function dictAdd(dict, setDict, itemId, object) {
  const dictCopy = { ...dict };
  dictCopy[itemId] = object;
  setDict(dictCopy);
}

export function dictDelete(dict, setDict, itemId) {
  const newDict = { ...dict };
  delete newDict[itemId];
  setDict(newDict);
}

/**
 * Like a map function but applied to an object instead of a list
 * @param   {Object}   obj    Source Object
 * @param   {function} vMaker Function to be applied to an obj value or an obj (key, value) pair to obtain a new value
 * @param   {function} kMaker Function to be applied to an obj key or an obj (key, value) pair to obtain a new key
 * @returns {Object}          Object whose keys are kMaker mapped to obj keys and values are vMaker mapped to obj values
 */
export const objMap = (obj, vMaker, kMaker = (k) => k) => (
  Object.keys(obj).reduce((prev, key) => {
    const vMakerArgs = vMaker.length === 2 ? [key, obj[key]] : [obj[key]];
    const kMakerArgs = kMaker.length === 2 ? [key, obj[key]] : [key];
    // eslint-disable-next-line no-param-reassign
    prev[kMaker(...kMakerArgs)] = vMaker(...vMakerArgs);
    return prev;
  }, {})
);

export const fill = (obj, value) => (
  objMap(obj, () => value)
);

export const isVoid = (value, voidValue = '') => (
  value === voidValue || value === null || value === '' || value === undefined || value === [] || value === {}
);
