import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EnhancedTable from './reusableComponents/enhancedTable/EnhancedTable';

export default function Constraints(props) {
  const { t } = useTranslation();

  const {
    products,
    properties,
    renameProduct,
    addProduct,
    deleteProduct,
    productConstraints,
    updateProductConstraint,
    productsProperties,
    updateProductProperty,
  } = props;
  const theme = useTheme();
  const color = theme.palette.lightSecondary;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        border: 5,
        borderColor: color.main,
        borderRadius: 1,
      }}
    >
      <Typography variant="h4" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('main_page.constraints.title')}
      </Typography>
      <EnhancedTable
        tableTitle={t('main_page.constraints.prod_table_title')}
        items={productConstraints}
        naming={products}
        hiddens={['id', 'weight']}
        updateItem={updateProductConstraint}
        addItem={addProduct}
        updateName={renameProduct}
        deleteItem={deleteProduct}
        titles={t('main_page.constraints.prod_table_cols', { returnObjects: true })}
        background={color}
      />
      <EnhancedTable
        tableTitle={t('main_page.constraints.prod_prop_table_title')}
        items={productsProperties}
        naming={products}
        hiddens={['id']}
        updateItem={updateProductProperty}
        addItem={addProduct}
        updateName={renameProduct}
        deleteItem={deleteProduct}
        titles={[t('main_page.constraints.product'), ...Object.values({ ...properties }).map((prop) => prop.name)]}
        background={color}
        voidable={false}
      />
    </Box>
  );
}
