import React from 'react';
import Box from '@mui/material/Box';
import { Input } from '@mui/material';

export default function InputTitle(props) {
  const { add, placeholder } = props;
  const [content, setContent] = React.useState('');

  const handleProductChange = (event) => {
    setContent(event.target.value);
  };

  const handleProductSubmit = (event) => {
    event.preventDefault();
    if (content !== '') {
      add(content);
      setContent('');
    }
  };

  return (
    <Box sx={{ flexGrow: 1, flexBasis: 1 }}>
      <form onSubmit={handleProductSubmit}>
        <Input
          type="text"
          placeholder={placeholder}
          value={content}
          onChange={handleProductChange}
          onBlur={handleProductSubmit}
        />
      </form>
    </Box>
  );
}
