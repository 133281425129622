export function round(value, decimals) {
  return roundNoFixed(value, decimals).toFixed(decimals);
}

export function roundP(value, decimals) {
  if (typeof (value) == 'string') {
    const lastChar = value.charAt(value.length - 1);
    if (lastChar === '%') {
      const fvalue = round(parseFloat(value.slice(0, -1)), decimals);
      return `${fvalue}%`;
    }
    return value;
  }
  return round(value, decimals);
}

export function roundNoFixed(value, decimals) {
  const decimalFix = `${1}e${decimals}`;
  return Number(Math.round((value + Number.EPSILON) * decimalFix) / decimalFix);
}
