import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Refresh from '@mui/icons-material/Refresh';
import version from 'staticFiles/version.txt';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link as MUILink } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Footer({ minimal = false }) {
  const { t } = useTranslation();
  const [appVersion, setappVersion] = useState('');
  const [swWaiting, setSwWaiting] = useState(undefined);

  useEffect(() => {
    fetch(version)
      .then((r) => r.text())
      .then((text) => {
        setappVersion(text);
      });

    // If we see that the service worker is waiting
    // then we can display a more visible button than the refresh at the bottom
    window.addEventListener('onSwUpdate', (event) => {
      setSwWaiting(event.detail.waiting);
    });
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        if (serviceWorkerRegistration.waiting) {
          setSwWaiting(serviceWorkerRegistration.waiting);
        }
      });
    }
    return () => window.removeEventListener('onSwUpdate', (event) => {
      setSwWaiting(event.detail.waiting);
    });
  }, []);

  const knownDatabases = ['firebase-heartbeat-database',
    'firebase-installations-database', 'firebaseLocalStorageDb',
    'firebase_remote_config', 'firestore/[DEFAULT]/labox-ws/main'];

  const clearIndexedDB = () => {
    if (window.indexedDB.databases) {
      window.indexedDB.databases().then((databases) => {
        databases.forEach((database) => {
          window.indexedDB.deleteDatabase(database.name);
        });
      });
    } else {
      // Fallback to predefined list
      knownDatabases.forEach((dbName) => {
        window.indexedDB.deleteDatabase(dbName);
      });
    }
  };
  async function clearCacheStorage() {
    const keys = await caches.keys();
    keys.forEach(async (key) => {
      await caches.delete(key);
    });
  }

  const clearApplicationCache = () => {
    localStorage.clear();
    sessionStorage.clear();

    // Clearing cookies
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }

    clearIndexedDB();
    clearCacheStorage();

    refreshSWAndReload();
  };

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const refreshSWAndReload = () => {
    // This will directly look for the service worker if it is
    // waiting and skip it then reload the page
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((readyRegistration) => {
        if (readyRegistration.waiting) {
          readyRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
      });
    }
    sleep(2000);
    window.location.reload();
  };

  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 5,
        mb: 3,
      }}
    >
      {minimal === false && (
        <Typography variant="caption">
          {t('labox-apps')}
          <MUILink
            component={Link}
            to="/contact"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            contact@labox-apps.com
          </MUILink>
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Typography variant="caption">
          {t('settings.app_version', { appVersion })}
        </Typography>

        <Button
          variant="text"
          size="small"
          onClick={refreshSWAndReload}
          endIcon={<Refresh />}
        >
          <Typography variant="caption">
            {t('reload')}
          </Typography>
        </Button>
      </Box>
      <Button
        sx={{ mt: 3 }}
        variant="text"
        size="small"
        onClick={clearApplicationCache}
        endIcon={<Refresh />}
      >
        <Typography variant="caption">
          {t('clear_cache')}
        </Typography>
      </Button>
      <Snackbar
        open={swWaiting}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity="info"
          action={(
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSwWaiting(undefined)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <Typography variant="span">
              {t('new_version')}
            </Typography>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                swWaiting.postMessage({ type: 'SKIP_WAITING' });
                window.location.reload();
              }}
              endIcon={<Refresh />}
            >
              {t('reload')}
            </Button>

          </Box>
        </Alert>
      </Snackbar>
    </Box>
  );
}
