import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import {
  Button, Menu, MenuItem,
} from '@mui/material';
import createXlsxFromJson from 'components/utils/createxlsxFromJson';
import { useTranslation } from 'react-i18next';

export default function DownloadButton(props) {
  const { data } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadJSON = () => {
    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    saveAs(blob, 'optimization.json');
  };

  const downloadXLSX = () => {
    const workbook = createXlsxFromJson(data);

    const xlsxBlob = new Blob(
      [XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })],
      { type: 'application/octet-stream' },
    );
    saveAs(xlsxBlob, 'data.xlsx');
    handleClose();
  };

  return (
    <>
      <Button color="primary" variant="outlined" endIcon={<DownloadIcon />} onClick={handleClick}>
        {t('download')}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={downloadJSON}>JSON</MenuItem>
        <MenuItem onClick={downloadXLSX}>XLSX</MenuItem>
      </Menu>
    </>
  );
}
