import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Optimize from './Optimize';
import OptimizationResult from './OptimizationResult';
import DownloadButton from './reusableComponents/DownloadButton';

export default function Solutions(props) {
  const { t } = useTranslation();

  const {
    products,
    properties,
    productsProperties,
    productConstraints,
    propertyConstraints,
    finalVolume,
    optimizationResult,
    setOptimizationResult,
    addResult,
    dumpJson,
  } = props;

  const [resultColor, setResultColor] = useState('background.main');

  useEffect(() => {
    if (optimizationResult === null) {
      setResultColor('background.main');
    } else if (optimizationResult.status === 'pending') {
      setResultColor('background.main');
    } else if (optimizationResult.status === 'error') {
      setResultColor('error.main');
    } else if (optimizationResult.success) {
      setResultColor('success.main');
    } else {
      setResultColor('error.main');
    }
  }, [optimizationResult, setResultColor]);

  const deduceTotalVolume = () => {
    if (finalVolume === '' && optimizationResult !== null && 'success' in optimizationResult) {
      // eslint-disable-next-line guard-for-in
      for (const productId in productConstraints) {
        const minVol = productConstraints[productId].lower_bound;
        const maxVol = productConstraints[productId].upper_bound;
        if (minVol !== '' && minVol === maxVol) {
          const proportion = parseFloat(optimizationResult.proportions[productId].slice(0, -1)) / 100;
          return proportion === 0 ? 0 : parseFloat(minVol) / proportion;
        }
      }
    }
    return finalVolume;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        border: 5,
        borderColor: resultColor,
        borderRadius: 1,
      }}
    >
      <Typography variant="h4" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('main_page.solutions.title')}
      </Typography>
      <Optimize
        products={products}
        properties={properties}
        productsProperties={productsProperties}
        propertyConstraints={propertyConstraints}
        productConstraints={productConstraints}
        finalVolume={finalVolume}
        setOptimizationResult={setOptimizationResult}
        addResult={addResult}
      />
      <OptimizationResult
        optimizationResult={optimizationResult}
        products={products}
        properties={properties}
        totalVolume={deduceTotalVolume()}
        dumpJson={dumpJson}
      />
    </Box>
  );
}
