import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { fill, objMap } from './utils/objectManipulation';
import { CompositionTable, emptyVolume } from './CompositionTable';
import EnhancedTable from './reusableComponents/enhancedTable/EnhancedTable';
import DownloadButton from './reusableComponents/DownloadButton';

export default function OptimizationResult(props) {
  const {
    optimizationResult, products, properties, totalVolume, dumpJson,
  } = props;

  if (optimizationResult == null) {
    return <EmptyResult />;
  }
  if (optimizationResult.status === 'pending') {
    return <PendingResult />;
  }
  if (optimizationResult.status === 'error') {
    return <ErrorResult error={optimizationResult.error} />;
  }
  if (optimizationResult.status === 'success') {
    return (
      <SuccessfulResult
        result={optimizationResult}
        products={products}
        properties={properties}
        totalVolume={totalVolume}
        dumpJson={dumpJson}
      />
    );
  }
  if (optimizationResult.status === 'failure') {
    return (
      <FailedResult
        result={optimizationResult}
        products={products}
        properties={properties}
        totalVolume={totalVolume}
      />
    );
  }
}

export function EmptyResult(props) {
  return <Box />;
}

export function NeutralResult(props) {
  const { t } = useTranslation();

  const {
    result, products, properties, totalVolume, color, dumpJson,
  } = props;

  const getDefaultVolumes = () => {
    const defaultEmptyVolumes = fill(result.proportions, emptyVolume);
    const proportionsFloat = objMap(result.proportions, (v) => (parseFloat(v.slice(0, -1)) / 100));
    // eslint-disable-next-line no-nested-ternary
    if ('volumes' in result) {
      return objMap(result.volumes, parseFloat);
    }
    if (totalVolume === '') {
      return defaultEmptyVolumes;
    }
    return objMap(proportionsFloat, (prop) => prop * totalVolume);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      pb: 2,
      alignItems: 'center',
    }}
    >
      <Typography>{result.message}</Typography>
      <CompositionTable
        products={products}
        proportions={result.proportions}
        volumes={getDefaultVolumes()}
        background={color}
      />
      <EnhancedTable
        tableTitle={t('main_page.solutions.prop_table_title')}
        items={objMap(result.mixture_properties, (v) => ({ value: v }))}
        naming={properties}
        background={color}
        editMode="close"
        decimals={1}
        titles={t('main_page.solutions.prop_table_cols', { returnObjects: true })}
      />
      {dumpJson && (
        <DownloadButton data={dumpJson()} />
      )}

    </Box>
  );
}

export function SuccessfulResult(props) {
  const {
    result, products, properties, totalVolume, dumpJson,
  } = props;
  const theme = useTheme();
  return (
    <NeutralResult
      result={result}
      products={products}
      properties={properties}
      color={theme.palette.lightSuccess}
      totalVolume={totalVolume}
      dumpJson={dumpJson}
    />
  );
}

export function FailedResult(props) {
  const {
    result, products, properties, totalVolume,
  } = props;
  const theme = useTheme();
  return (
    <NeutralResult
      result={result}
      products={products}
      properties={properties}
      color={theme.palette.error}
      totalVolume={totalVolume}
    />
  );
}

export function PendingResult() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export function ErrorResult(props) {
  const { error } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: theme.palette.error.main,
      }}
    >
      <Typography>
        {error.message}
      </Typography>
    </Box>
  );
}
