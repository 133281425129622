import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';

export default function parseXlsx(content) {
  const dataContent = new Uint8Array(content);
  const workbook = XLSX.read(dataContent, { type: 'array' });
  const firstSheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[firstSheetName];

  let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });

  // Remove empty rows
  jsonData = jsonData.filter((row) => row.some((cell) => cell !== ''));

  // Remove empty columns
  const transpose = (matrix) => matrix[0].map((_, colIndex) => matrix.map((row) => row[colIndex]));
  const transposedData = transpose(jsonData);
  const filteredTransposedData = transposedData.filter((column) => column.some((cell) => cell !== ''));
  jsonData = transpose(filteredTransposedData);

  const data = {
    properties: {},
    productsProperties: {},
    products: {},
    propertyConstraints: {},
    productConstraints: {},
    finalVolume: jsonData[2][2],
    date: new Date().toLocaleString(),
    name: new Date().toLocaleString(),
    id: uuidv4(),
  };
    // Map to store property ids by name
  const propertyMap = {};

  // Function to generate unique IDs for properties and products
  function generateId() {
    return uuidv4();
  }

  // Extract propertyNames and data
  const propertyNames = jsonData[0].slice(6);
  const objectives = jsonData.slice(1, 4);
  const products = jsonData.slice(5);

  // Extract properties
  propertyNames.forEach((header) => {
    const id = generateId();
    propertyMap[header.trim()] = id;
    data.properties[id] = {
      id,
      name: header.trim(),
    };
  });

  // Extract property constraints
  objectives.forEach((row, index) => {
    const propertyValues = row.slice(6);
    propertyValues.forEach((value, i) => {
      const id = propertyMap[propertyNames[i].trim()];
      if (!data.propertyConstraints[id]) {
        data.propertyConstraints[id] = {
          lower_bound: '',
          target: '',
          weight: '',
          upper_bound: '',
        };
      }
      if (index === 0) data.propertyConstraints[id].lower_bound = value || '';
      if (index === 1) data.propertyConstraints[id].target = value || '';
      if (index === 2) data.propertyConstraints[id].upper_bound = value || '';
    });
  });

  // Extract products and their properties
  products.forEach((row) => {
    const productName = row[0];
    const productId = generateId();

    data.products[productId] = {
      id: productId,
      name: productName.trim(),
    };

    const properties = row.slice(6).reduce((acc, value, index) => {
      const propertyId = propertyMap[propertyNames[index].trim()];
      acc[propertyId] = value;
      return acc;
    }, {});

    data.productsProperties[productId] = properties;

    // Extract product constraints
    data.productConstraints[productId] = {
      lower_bound: '',
      target: '',
      weight: '',
      upper_bound: row[3] || '',
    };
  });

  // Write the result to a JSON file
  // console.log(JSON.stringify(data, null, 2));
  return data;
}
