import PropTypes from 'prop-types';

export const basePropTypes = [PropTypes.number, PropTypes.string, PropTypes.bool];

export const baseTypes = ['number', 'string', 'boolean'];

// depth is the max allowed depth of the object
// unEven means that some branches can be shorter than others
// strict means that tree depth MUST be equal to depth
// accept array means that objects in the trees can be an array
export const objectOfDepth = (
  depth,
  unEven = false,
  strict = true,
  acceptArray = false,
  allowedBaseTypes = baseTypes,
) => (
  (props, propName, componentName) => (
    objectOfDepthRec(
      propName,
      props[propName],
      0,
      0,
      0,
      depth,
      unEven,
      strict,
      acceptArray,
      allowedBaseTypes,
    ).error)
);

const objectOfDepthRec = (
  currentPropName,
  currentPropValue,
  currentDepth,
  maxDepth,
  maxAllowedDepth,
  unEven,
  strict,
  acceptArray,
  allowedBaseTypes,
) => {
  let children;
  const childArgs = [currentDepth + 1, maxDepth, maxAllowedDepth, unEven, strict, acceptArray, allowedBaseTypes];
  if (Array.isArray(currentPropValue)) {
    if (!acceptArray && currentPropValue.length) {
      return {
        error: new Error(`prop ${currentPropName} at depth ${currentDepth} is an Array.`),
        maxDepth: Math.max(currentDepth, maxDepth),
      };
    }
    children = currentPropValue.map((child) => ({ name: '', value: child }));
  } else if (typeof currentPropValue === 'object' && currentPropValue !== null) {
    children = Object.keys(currentPropValue).map((propName) => (
      { name: propName, value: currentPropValue[propName] }));
  }

  // not a leaf
  if (children) {
    if (currentDepth === maxDepth) return { error: new Error(`depth greater than ${maxAllowedDepth}`) };
    const result = { error: null, maxDepth: currentDepth };
    for (let i = 0; i < children.length; i += 1) {
      const child = children[i];
      const childValidation = objectOfDepthRec(child.name, child.value, ...childArgs);
      if (childValidation.error) {
        return { error: childValidation.error };
      }
      if (childValidation.maxDepth >= result.maxDepth) {
        result.maxDepth = childValidation.maxDepth;
      } else if (!unEven) {
        return {
          error: new Error(
            `sub tree ${child.name} at depth ${currentDepth + 1} is of depth ${childValidation.maxDepth} 
            while max depth is ${result.maxDepth}, which makes the tree uneven`,
          ),
        };
      }
    }
    return result;
  }

  // a leaf of bad depth
  if (currentDepth < maxAllowedDepth && strict) {
    return { error: new Error(`leaf ${currentPropName} is at depth ${currentDepth} < ${maxAllowedDepth}`) };
  }
  // valid leaf type
  if (allowedBaseTypes.includes(typeof currentPropValue)) {
    return {
      error: null,
      maxDepth: currentDepth,
    };
  }
  // invalid leaf type
  return {
    error: new Error(
      `prop ${currentPropName} at depth ${currentDepth} is of forbidden type ${typeof currentPropValue}.`,
    ),
  };
};

export const functionOfNbArgs = (nbArgs) => (
  // eslint-disable-next-line consistent-return
  (props, propName, componentName) => {
    const fn = props[propName];
    if (!('apply' in fn) || fn.length !== nbArgs) {
      return new Error(`${propName} supplied to ${componentName} must be a function with ${nbArgs} args`);
    }
  }
);

export const checkConstraintsBounds = (constraints) => {
  // eslint-disable-next-line guard-for-in
  for (const itemId in constraints) {
    const constraint = constraints[itemId];
    if (
      ('lower_bound' in constraint && constraint.lower_bound !== '')
      && ('upper_bound' in constraint && constraint.upper_bound !== '')
      && (constraint.lower_bound > constraint.upper_bound)
    ) {
      return itemId;
    }
  }
  return null;
};

const finalVolumeCompatibleWithMaxVolumes = (volumeConstraints, finalVolume) => {
  let maxVolSum = 0;
  // eslint-disable-next-line guard-for-in
  for (const itemId in volumeConstraints) {
    const maxVol = volumeConstraints[itemId].upper_bound;
    if (maxVol === '') return true;
    maxVolSum += parseFloat(maxVol);
  }
  return maxVolSum >= finalVolume;
};
const finalVolumeCompatibleWithMinVolumes = (volumeConstraints, finalVolume) => {
  let minVolSum = 0;
  // eslint-disable-next-line guard-for-in
  for (const itemId in volumeConstraints) {
    const minVol = volumeConstraints[itemId].lower_bound;
    if (minVol !== '') minVolSum += parseFloat(minVol);
  }
  return minVolSum <= finalVolume;
};

export const checkFinalVolume = (volumeConstraints, finalVolume) => {
  if (finalVolume !== '') {
    if (!finalVolumeCompatibleWithMaxVolumes(volumeConstraints, finalVolume)) {
      return 'Sum of volumes upper bounds is less than desired final volume.';
    }
    if (!finalVolumeCompatibleWithMinVolumes(volumeConstraints, finalVolume)) {
      return 'Sum of volumes lower bounds is more than desired final volume.';
    }
  }
  return null;
};
