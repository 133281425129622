import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TargetVolume from './TargetVolume';
import EnhancedTable from './reusableComponents/enhancedTable/EnhancedTable';

export default function Objectives(props) {
  const { t } = useTranslation();

  const {
    properties,
    renameProperty,
    addProperty,
    deleteProperty,
    propertyConstraints,
    updatePropertyConstraint,
    finalVolume,
    setFinalVolume,
  } = props;
  const theme = useTheme();
  const color = theme.palette.lightPrimary.dark;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        border: 5,
        borderColor: color,
        borderRadius: 1,
      }}
    >
      <Typography variant="h4" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('main_page.objectives.title')}
      </Typography>
      <EnhancedTable
        tableTitle={t('main_page.objectives.prop_table_title')}
        items={propertyConstraints}
        naming={properties}
        hiddens={['id', 'weight']}
        updateItem={updatePropertyConstraint}
        addItem={addProperty}
        updateName={renameProperty}
        deleteItem={deleteProperty}
        titles={t('main_page.objectives.prop_table_cols', { returnObjects: true })}
        background={theme.palette.lightPrimary}
      />
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('main_page.objectives.final_volume')}
      </Typography>
      <TargetVolume targetVolume={finalVolume} setTargetVolume={setFinalVolume} />
    </Box>
  );
}
