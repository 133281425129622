import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputTitle from './InputTitle';

export default function EnhancedInputRow(props) {
  const { t } = useTranslation();

  const {
    bgcolor, addItem, fields, dense,
  } = props;

  return (
    <TableRow
      sx={{ background: bgcolor }}
      hover
      tabIndex={-1}
      style={{ height: dense ? 43 : 53 }}
    >
      <TableCell
        padding="checkbox"
      />
      <TableCell
        component="th"
        scope="row"
        padding="none"
      >
        <InputTitle
          component="td"
          add={addItem}
          placeholder={t('main_page.create')}
        />
      </TableCell>
      {fields.filter((field) => field !== 'name').map((field) => (
        <TableCell component="td" key={field} align="right" />
      ))}
    </TableRow>
  );
}
