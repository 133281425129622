import React from 'react';
import { Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function EnhancedTableToolbar(props) {
  const { title } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
        color="inherit"
      >
        {title}
      </Typography>
    </Toolbar>
  );
}
