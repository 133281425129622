import React from 'react';
import { useTranslation } from 'react-i18next';
import { fill, objMap } from './utils/objectManipulation';
import { roundP } from './utils/round';
import EnhancedTable from './reusableComponents/enhancedTable/EnhancedTable';

export const emptyVolume = '-';

export function CompositionTable(props) {
  const { t } = useTranslation();

  const {
    products, proportions, volumes, background,
  } = props;

  const totalName = 'Total';
  const productTotal = { [totalName]: { name: totalName, id: totalName } };
  const productsTotal = { ...products, ...productTotal };
  const proportionsFloat = objMap(proportions, (v) => (parseFloat(v.slice(0, -1)) / 100));

  const [editedVolumes, setEditedVolumes] = React.useState(volumes);
  const [totalVolume, setTotalVolume] = React.useState();
  const [displayItems, setdisplayItems] = React.useState({});

  React.useEffect(() => {
    setEditedVolumes(volumes);
  }, [volumes]);

  React.useEffect(() => {
    const getTotalVolume = (volumesDict) => {
      const firstProduct = Object.keys(proportions)[0];
      if (volumesDict[firstProduct] === emptyVolume) {
        return emptyVolume;
      }
      return roundP(Object.values(volumesDict).reduce((a, b) => a + b), 1);
    };
    const newTotalVolume = getTotalVolume(editedVolumes);
    const getDisplayItems = (propors, vols) => {
      const items = objMap(propors, (k, v) => ({
        proportion: v,
        volume: vols[k],
      }));
      items[totalName] = { proportion: '100%', volume: newTotalVolume };
      return items;
    };
    setTotalVolume(newTotalVolume);
    setdisplayItems(getDisplayItems(proportions, editedVolumes));
  }, [proportions, editedVolumes]);

  const blankVolumes = () => {
    setEditedVolumes(fill(volumes, emptyVolume));
  };

  const updateTotalVolume = (newTotalVolume) => {
    if (newTotalVolume === emptyVolume || newTotalVolume === '') {
      blankVolumes();
    } else {
      setEditedVolumes(objMap(proportionsFloat, (v) => v * newTotalVolume));
    }
  };

  const updateComp = (productId, field, newVolume) => {
    if (field !== 'volume') {
      throw new Error('Only volume can be edited in composition');
    }
    if (productId === totalName && newVolume !== totalVolume) {
      updateTotalVolume(newVolume);
    } else if (productId !== totalName && newVolume !== editedVolumes[productId]) {
      const newTotalVolume = newVolume === '' ? emptyVolume : newVolume / proportionsFloat[productId];
      updateTotalVolume(newTotalVolume);
    }
  };

  const validRow = (row) => {
    const propFloat = parseFloat(row.proportion.slice(0, -1));
    return (
      (row.volume >= 0 || row.volume === '')
      && (propFloat > 0 || row.volume === editedVolumes[row.id])
    );
  };

  return (
    <EnhancedTable
      tableTitle={t('main_page.solutions.comp_table_title')}
      items={displayItems}
      naming={productsTotal}
      updateItem={updateComp}
      background={background}
      dense
      titles={t('main_page.solutions.comp_table_cols', { returnObjects: true })}
      editMode={['close', 'doubleClick']}
      voidValue="-"
      footers={[totalName]}
      isValid={validRow}
      decimals={2}
    />
  );
}
