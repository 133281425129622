import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import {
  useFirebaseApp,
  AuthProvider,
  AnalyticsProvider,
  FunctionsProvider,
  FirebaseAppProvider,
  FirestoreProvider,
} from 'reactfire';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { light } from '@mui/material/styles/createPalette';
import Welcome from './components/commonPages/Welcome';
import { initI18next } from './i18n/i18next';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'optimix.labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:493d418b96ba5e1d297452',
  measurementId: 'G-P6NPXLZ2KR',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#79a2ae',
      dark: '#6695A3',
      light: '#8CAFBA',
      contrastText: 'white',
    },
    secondary: {
      main: '#e6c16b',
      dark: '#E4BD62',
      contrastText: 'black',
    },
    success: {
      main: '#B2D2A4',
      dark: '#A2C992',
      contrastText: 'black',
    },
    error: {
      main: '#E5736C',
      dark: '#E05952',
      contrastText: 'black',
    },
    warning: {
      main: '#f4a261',
      contrastText: 'black',
    },
    info: {
      main: '#424242',
      contrastText: 'white',
    },
    text: {
      primary: '#000000',
    },
    background: {
      paper: '#ffffff',
      default: '#fafafa',
    },
    lightPrimary: {
      main: '#d9e5e8',
      dark: '#bcd0d6',
      light: '#e7eef0',
      contrastText: 'white',
    },
    lightSecondary: {
      main: '#f9d6a2',
      dark: '#f7c173',
      light: '#fcead0',
      contrastText: 'white',
    },
    lightSuccess: {
      main: '#d8e8d1',
      dark: '#c5ddbb',
      light: '#ecf4e8',
      contrastText: 'black',
    },
  },
});

function AllReactFireProviders() {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const firestoreInstance = getFirestore(app);
  const functionsInstance = getFunctions(app);
  const storageInstance = getStorage(app);
  if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(firestoreInstance, 'localhost', 4401);
    connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
    connectStorageEmulator(storageInstance, 'localhost', 9199);
  }

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <AuthProvider sdk={auth}>
        <FunctionsProvider sdk={functionsInstance}>
          <FirestoreProvider sdk={firestoreInstance}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Container>
                  <Box>
                    <Welcome />
                  </Box>
                </Container>
              </ThemeProvider>
            </StyledEngineProvider>
          </FirestoreProvider>
        </FunctionsProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}

function App() {
  initI18next();
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <div className="App" style={{ whiteSpace: 'pre-line' }}>
        <AllReactFireProviders />
      </div>
    </FirebaseAppProvider>
  );
}

export default App;
