import {
  collection, getDocs, query, where,
} from 'firebase/firestore';

/* eslint-disable import/prefer-default-export */
export const getAccessGrants = async (db, user, activeOrganization) => {
  const basePath = activeOrganization ? [
    'organizations', activeOrganization, 'accessGrants',
  ] : [
    'users', user?.uid, 'accessGrants',
  ];

  const accessGrantsRef = collection(db, ...basePath);

  const now = new Date();
  const validGrantsQuery = query(
    accessGrantsRef,
    where('expirationDate', '>', now),
    where('status', '==', 'active'),
  );

  const querySnapshot = await getDocs(validGrantsQuery);
  const validGrants = [];
  const grantNames = new Set();

  querySnapshot.forEach((d) => {
    validGrants.push(d.data());
    grantNames.add(d.data().name);
  });

  const uniqueGrantNames = Array.from(grantNames);

  return {
    validGrants,
    uniqueGrantNames,
  };
};
