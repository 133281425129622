import React from 'react';
import Typography from '@mui/material/Typography';
import { Input } from '@mui/material';
import { isVoid } from '../utils/objectManipulation';
import { roundP } from '../utils/round';

export default function Editable(props) {
  const {
    value,
    updateValue,
    inputType = 'text',
    isValid = (v) => true,
    decimals = null,
    voidable = false,
    voidValue = '',
    editMode = 'doubleClick',
    sx,
  } = props;

  const [content, setContent] = React.useState(value);
  const [editing, setEditing] = React.useState(editMode === 'open');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setContent(value);
  }, [value]);

  React.useEffect(() => {
    const allowedEditModes = ['click', 'doubleClick', 'open', 'close'];
    if (!allowedEditModes.includes(editMode)) {
      throw new Error(`editMode ${editMode} not allowed.\nAvailable modes are ${allowedEditModes}.`);
    }
  }, [editMode]);

  const pretty = (displayValue) => {
    if (inputType === 'number' && decimals !== null) {
      return roundP(displayValue, decimals);
    }
    return displayValue;
  };

  const getError = (rawValue) => {
    const parsedValue = parseValue(rawValue);
    return isVoid(parsedValue, voidValue) ? !voidable : !isValid(parsedValue);
  };

  const parseValue = (rawValue) => (
    inputType !== 'number' || isVoid(rawValue, voidValue) ? rawValue : parseFloat(rawValue)
  );

  const handleChange = (event) => {
    setContent(event.target.value);
    setError(getError(event.target.value));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handleEditing = () => {
    setEditing(true);
    setContent(value);
  };

  const handleClick = () => {
    if (editMode === 'click') {
      handleEditing();
    }
  };

  const handleDoubleClick = () => {
    if (editMode === 'doubleClick') {
      handleEditing();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const rawValue = event.target.value;
    const isError = getError(rawValue);
    setError(isError);
    if (!isError) {
      updateValue(parseValue(rawValue));
      setEditing(editMode === 'open');
    }
  };

  return (
    <>
      {!editing && (
      <Typography sx={sx} onClick={handleClick} onDoubleClick={handleDoubleClick}>{pretty(value)}</Typography>
      )}
      {editing && (
      <Input
        sx={sx}
        type={inputType}
        value={content}
        onBlur={handleSubmit}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        error={error}
        autoFocus
      />
      )}
    </>
  );
}
