export const setCookiesAccepted = (store, boolean) => {
  store.setState({ cookiesAccepted: boolean });
};

export const setUserUnits = (store, units) => {
  store.setState({ userUnits: units });
};

export const setClientPath = (store, path) => {
  store.setState({ clientPath: path });
};

export const setSnackbarMessage = (store, options = { message: '', severiy: 'info', duration: 15000 }) => {
  store.setState({
    globalSnackbar: {
      display: true,
      message: options.message,
      severity: options.severity,
      displayDuration: options.duration,
    },
  });
};

export const closeSnackbar = (store) => {
  store.setState({ globalSnackbar: { display: false, message: '', severity: 'info' } });
};

export const setUserRoles = (store, anArray) => {
  store.setState({ roles: anArray });
};

export const setUserStatus = (store, status) => {
  store.setState({ userStatus: status });
};

export const setActiveOrganization = (store, organization) => {
  store.setState({ activeOrganization: organization });
};

export const setDefaultBarrelCsvMapping = (store, object) => {
  store.setState({ defaultBarrelCsvMapping: object });
};

export const setAccessGrants = (store, anArray) => {
  store.setState({ accessGrants: anArray });
};

export const setAccessGrantNames = (store, anArray) => {
  store.setState({ accessGrantNames: anArray });
};

export const setUserOrganizations = (store, organizations) => {
  store.setState({ userOrganizations: organizations });
};
