import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Title(props) {
  const { content } = props;
  return (
    <Typography component="span">
      <Box sx={{ fontWeight: 'bold' }}>
        {content}
      </Box>
    </Typography>
  );
}
