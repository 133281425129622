import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { transparent } from '../utils/display';

export default function DeleteButton(props) {
  const { deleteFunc, itemId } = props;

  return (
    <IconButton
      aria-label="delete"
      color="error"
      onClick={() => deleteFunc(itemId)}
      sx={{ '&:hover': { color: 'error.dark' }, color: transparent }}
    >
      <DeleteIcon />
    </IconButton>
  );
}
