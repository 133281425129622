import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useUser } from 'reactfire';
import AccountBox from '@mui/icons-material/AccountBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LanguageSelect from './LanguageSelect';
import logo from '../../staticFiles/opti-mix-logo.png';
import { inAdminPage } from '../utils/authentication';

export default function TopButtons(props) {
  const { menuActions } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const { data: user } = useUser();
  const [isAdmin, setIsAdmin] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function displayAdminButton() {
    return isAdmin && !inAdminPage(location) && displayAccountButton();
  }

  function displayOperatorButton() {
    return isAdmin && inAdminPage(location) && displayAccountButton();
  }

  function displayAccountButton() {
    return !location.pathname.includes('account')
      && !location.pathname.includes('pdfViewer')
      && !location.pathname.includes('volumeConversion');
  }

  function displayMenu() {
    return location.pathname.includes('operation') || location.pathname.includes('pdfViewer');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 1,
      }}
    >
      <LogoOrReturn />
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {displayAdminButton() && (
          <Button
            size="small"
            variant="contained"
            color="info"
            component={Link}
            to="admin"
          >
            Admin
          </Button>
        )}
        {displayOperatorButton() && (
          <Button
            size="small"
            variant="contained"
            color="info"
            component={Link}
            to="/"
          >
            {t('admin.operator')}
          </Button>
        )}
        {displayAccountButton() && (
          <IconButton
            aria-label="account"
            component={Link}
            to="account"
            sx={{ p: 0, fontSize: 37 }}
            color="primary"
          >
            <AccountBox fontSize="inherit" />
          </IconButton>
        )}
        {displayMenu() && (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? 'long-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <MyMenu
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          menuOpen={menuOpen}
          actions={menuActions}
        />
        {!displayMenu() && <LanguageSelect />}
      </Box>
    </Box>
  );
}

function LogoOrReturn() {
  const navigate = useNavigate();
  const location = useLocation();
  if (location.pathname === '/') {
    return (
      <img
        src={logo}
        style={{ height: 40, objectFit: 'cover' }}
        alt="Automated Labox"
      />
    );
  }
  return (
    <IconButton size="large" onClick={() => navigate(-1)}>
      <ArrowBack />
    </IconButton>
  );
}

function MyMenu(props) {
  const {
    anchorEl, handleMenuClose, menuOpen, actions = [],
  } = props;

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        style: {
          maxHeight: 48 * 4.5,
          width: '20ch',
        },
      }}
    >
      {actions.map((action) => (
        <MenuItem key={action.name} onClick={() => { action.callback(); handleMenuClose(); }}>
          {action.name}
        </MenuItem>
      ))}
      <MenuItem
        key="lang"
        disableRipple
        disableTouchRipple
        sx={{
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}
      >
        <LanguageSelect />
      </MenuItem>
    </Menu>
  );
}
