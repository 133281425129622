import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { ButtonGroup, Input } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

export default function History(props) {
  const { t } = useTranslation();

  const {
    optimizationsHistory, instantiateHistory, deleteAllHistory, renameHistory, deleteHistory,
  } = props;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'sticky',
      top: '0',
    }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('main_page.history.title')}
      </Typography>
      <ButtonGroup orientation="vertical" sx={{ gap: 1 }}>
        {optimizationsHistory.map((history, i) => (
          <HistoryItem
            key={history.id}
            history={history}
            instantiateHistory={instantiateHistory}
            deleteHistory={deleteHistory}
            renameHistory={renameHistory}
          />
        ))}
        <Button color="error" variant="outlined" onClick={deleteAllHistory}>
          {t('main_page.history.delete')}
        </Button>
      </ButtonGroup>
    </Box>
  );
}

export function HistoryItem(props) {
  const {
    history, instantiateHistory, deleteHistory, renameHistory,
  } = props;
  const [editing, setEditing] = useState(false);
  const [content, setContent] = useState(history.name);

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setEditing(false);
      handleSubmit(event);
    }
  };

  const handleEditing = () => {
    setEditing(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    renameHistory(history.id, event.target.value);
    setEditing(false);
  };

  return (
    <ButtonGroup variant="contained">
      <IconButton
        aria-label="Edit"
        onClick={handleEditing}
      >
        <EditIcon />
      </IconButton>
      {!editing && (
      <Button
        sx={{ flexGrow: 2 }}
        color={history.result.success ? 'success' : 'error'}
        onClick={() => instantiateHistory(history.id)}
      >
        {history.name}
      </Button>
      )}
      {editing && (
      <Input
        type="text"
        value={content}
        onBlur={handleSubmit}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      )}
      <IconButton
        aria-label="Edit"
        onClick={() => deleteHistory(history.id)}
      >
        <DeleteIcon />
      </IconButton>

    </ButtonGroup>
  );
}
