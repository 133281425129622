import React, { useEffect } from 'react';
import {
  Box, Typography, Paper, Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';
import {
  useSigninCheck,
  useFirebaseApp,
} from 'reactfire';
import { SignIn } from 'labox-ws-commons';
import useGlobal from 'global-state/store';
import {
  getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendEmailVerification,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import LaboxWS from 'icons/LaboxWS';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InstallGuide from './account/InstallGuide';

export default function SignInPage() {
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();
  const location = useLocation();
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const analytics = getAnalytics();
  const provider = new GoogleAuthProvider();

  const ourSignInWithPopup = () => signInWithPopup(auth, provider);

  useEffect(() => {
    if (signInCheckResult?.signedIn === true) {
      navigate('/', { replace: true });
    }
  }, [navigate, signInCheckResult?.signedIn]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        mt: 2,
        mb: 15,
      }}
    >
      <SignIn
        t={t}
        globalActions={globalActions}
        navigate={navigate}
        location={location}
        Link={Link}
        auth={auth}
        analytics={analytics}
        gtcuCookieName="agreed-to-general-terms-labox-optimix"
        ourSignInWithPopup={ourSignInWithPopup}
        signInWithEmailAndPassword={signInWithEmailAndPassword}
        createUserWithEmailAndPassword={createUserWithEmailAndPassword}
        logEvent={logEvent}
        sendEmailVerification={sendEmailVerification}
        LaboxWSIcon={LaboxWS}
      />
      <Paper
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <Typography variant="h6" component="div">
          {t('onboarding.start_button_label')}
        </Typography>
        <Button
          size="large"
          variant="contained"
          onClick={() => { navigate('/contact'); }}
          endIcon={<ArrowForwardIosIcon />}
        >
          {t('user_card.contact_us')}
        </Button>
      </Paper>
      {/* <Typography variant="h5" component="div" sx={{ mt: 3 }}>
        {t('welcome.welcome_title')}
      </Typography>
      <Paper elevation={0} sx={{ p: 3, mb: 5 }}>
        <Typography component="p" sx={{ textAlign: 'left' }}>
          {t('welcome.welcome_text', { joinArrays: '' })}
        </Typography>
      </Paper> */}
      <InstallGuide />
    </Box>
  );
}
