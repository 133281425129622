import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import DeleteButton from '../DeleteButton';
import Editable from '../Editable';

export default function EnhancedRow(props) {
  const {
    bgcolor,
    row,
    deleteItem,
    updateItem,
    updateName,
    fields,
    voidable,
    voidValue,
    editMode,
    decimals,
    isValid,
  } = props;

  return (
    <TableRow
      sx={{ background: bgcolor }}
      hover
      tabIndex={-1}
    >
      <TableCell
        padding="checkbox"
      >
        {deleteItem && (<DeleteButton deleteFunc={deleteItem} itemId={row.id} />)}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        padding="none"
      >
        <Editable
          component="td"
          value={row.name}
          updateValue={(newValue) => updateName(row.id, newValue)}
          inputType="text"
          editMode={updateName ? 'doubleClick' : 'close'}
        />
      </TableCell>
      {fields.filter((field) => field !== 'name').map((field, i) => (
        <TableCell key={field} align="center">
          <Editable
            component="td"
            value={row[field]}
            updateValue={(newValue) => updateItem(row.id, field, newValue)}
            inputType="number"
            voidable={voidable}
            voidValue={voidValue}
            decimals={decimals}
            editMode={Array.isArray(editMode) ? editMode[i] : editMode}
            isValid={(value) => isValid({ ...row, [field]: value })}
          />
        </TableCell>
      ))}
    </TableRow>
  );
}
