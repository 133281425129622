export async function isUserAdmin(user) {
  if (user !== null) {
    const token = await user.getIdTokenResult();
    if (token?.claims.admin !== undefined) {
      return token.claims.admin === 'true';
    }
  }
  return false;
}

export function inAdminPage(location) {
  return location.pathname.includes('admin');
}
