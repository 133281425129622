/* eslint-disable react/jsx-props-no-spreading, no-console */

import React from 'react';
import { useDropzone } from 'react-dropzone';

export default function DropZoneWrapper(props) {
  const { children, loadJson, loadXlsx } = props;

  const readFiles = (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      if (file.type === 'application/json') {
        const content = JSON.parse(reader.result);
        loadJson(content);
      } else {
        loadXlsx(reader.result);
      }
    };
    file.type === 'application/json' ? reader.readAsText(file) : reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: readFiles,
    accept: {
      'application/json': ['.json'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div {...getRootProps({
      onClick: (event) => event.stopPropagation(),
    })}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
}
